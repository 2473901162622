:root {
    --main-cell-color: white;
}

.board-container {
    display: inline-block;
}

@media (prefers-color-scheme: light) {
    .board-container {
        border: 5px solid black;
    }
}

@media (prefers-color-scheme: dark) {
    .board-container {
        /* The border color should match the dark:bg-gray-900 from Board.tsx. */
        border: 5px solid rgb(17 24 39);
    }
}

.grid {
    display: grid;
    grid-gap: 5px;
    width: fit-content;
}

.cell {
    justify-content: center;
    align-items: center;
    aspect-ratio: 1 / 1;
    display: flex;
    font-family: Arial;
    font-size: 2rem;
    font-weight: bold;
    cursor: default;
}

@media screen(sm) {
    .cell {
        font-size: 3rem;
    }
}

.selectable {
    cursor: pointer;
}

input {
    width: 100%;
    height: 100%;
    border-radius: 0;

    font-family: Arial;
    font-size: 3rem;
    font-weight: bold;
    text-align:center;

    border: none !important;
    outline:0;
    padding: 0;
}

input[type="search"] {
    -webkit-appearance: none;
}

input:focus {
    width: 100%;
    height: 100%;

    font-family: Arial;
    font-size: 3rem;
    font-weight: bold;
    align-self: center;

    outline:none!important;
}
